import SharedModule from "./shared.module";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { MAT_DIALOG_DEFAULT_OPTIONS } from "@angular/material/dialog";
import { AppComponent } from "./app.component";
import { NavComponent } from "./layout/nav/nav.component";
import { LeftnavComponent } from "./layout/leftnav/leftnav.component";
import { FooterComponent } from "./layout/footer/footer.component";
import { LoaderComponent } from "./layout/loader/loader.component";
import { SpinnerComponent } from "./layout/spinner/spinner.component";
import { HomeComponent } from "./web/home/home.component";
import { LoginComponent } from "./web/login/login.component";
import { LogoutComponent } from "./web/logout/logout.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { UsersComponent } from "./users/users.component";
import { GroupsComponent } from "./groups/groups.component";
import { GroupDialogComponent } from "./groups/new-group-dialog/group-dialog.component";
import { AuthGuard, RedirectGuard } from "./services/admin-plus/auth-guard.service";
import { UserComponent } from "./users/user/user.component";
import { GroupComponent } from "./groups/group/group.component";
import { MembersComponent } from "./groups/group/members/members.component";
import { GroupMembershipsComponent } from "./users/user/group-memberships/group-memberships.component";

/* Not needed */
import { ConfirmationDialogComponent } from "./layout/dialogs/confirmation-dialog/confirmation-dialog.component";
import { GroupMembershipsDialogComponent } from "./users/user/group-memberships-dialog/group-memberships-dialog.component";
import { SelectGroupsMembersComponent } from "./layout/dialogs/select-groups-members/select-groups-members.component";
import { MembersDialogComponent } from "./groups/group/members-dialog/members-dialog.component";
import { DeleteDialogComponent } from "./groups/group/members/dialogs/delete-dialog/delete-dialog.component";
import { DeleteGroupsDialogComponent } from "./groups/delete-groups-dialog/delete-groups-dialog.component";
import { DeleteGroupMembershipsDialogComponent } from "./users/user/group-memberships/delete-group-memberships-dialog/delete-group-memberships-dialog.component";
import { UsersNewUserComponent } from "./users/dialogs/users-new-user/users-new-user.component";
import { UsersDeleteUsersComponent } from "./users/dialogs/users-delete-users/users-delete-users.component";
import { OuSelectionComponent } from "./users/dialogs/ou-selection/ou-selection.component";
import { SelectGroupsComponent } from "./layout/dialogs/select-groups/select-groups.component";
import { EditMemberRolesComponent } from "./groups/group/members/dialogs/edit-member-roles/edit-member-roles.component";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { IdleComponent } from "./layout/dialogs/idle/idle.component";

/* Directives */
import { AppFiltersComponent } from "src/app/layout/app-filters/app-filters.component";
import { LeftNavService } from "src/app/services/admin-plus/leftnav.service";
import { TosPromptComponent } from "./web/login/tos-prompt/tos-prompt.component";
import { StorageComponent } from "./storage-plus/storage/storage.component";
import { StorageReportHistoricalLineChartComponent } from "./storage-plus/storage/layout/storage-report-historical-line-chart/storage-report-historical-line-chart.component";
import { StorageReportPieChartComponent } from "./storage-plus/storage/layout/storage-report-pie-chart/storage-report-pie-chart.component";
import { StorageReportUsersComponent } from "./storage-plus/storage/storage-report-users/storage-report-users.component";
import { StorageReportFilesComponent } from "./storage-plus/storage/storage-report-files/storage-report-files.component";
import { StorageReportUserComponent } from "./storage-plus/storage/storage-report-user/storage-report-user.component";
import { EuaPromptComponent } from "./web/login/eua-prompt/eua-prompt.component";
import { EuaComponent } from "./web/eua/eua.component";
import { TosComponent } from "./web/tos/tos.component";
import { ResetPasswordComponent } from "./users/dialogs/reset-password/reset-password.component";
import { StorageReportEmailsComponent } from "./storage-plus/storage/storage-report-emails/storage-report-emails.component";
import { EmailActionRequiredComponent } from "./storage-plus/storage/storage-report-emails/email-action-required/email-action-required.component";
import { EmailFilesDeletedComponent } from "./storage-plus/storage/storage-report-emails/email-files-trashed/email-files-trashed.component";
import { EmailFilesPendingDeletionComponent } from "./storage-plus/storage/storage-report-emails/email-files-pending-deletion/email-files-pending-deletion.component";
import { EmailHistoryTableComponent } from "./storage-plus/storage/storage-report-emails/email-history-table/email-history-table.component";
import { EmailReportCompleteComponent } from "./storage-plus/storage/storage-report-emails/email-report-complete/email-report-complete.component";
import { ConfirmationDialogTrashComponent } from "./layout/dialogs/confirmation-dialog-trash/confirmation-dialog-trash.component";
import { EmailActionRequiredRemovedComponent } from "./storage-plus/storage/storage-report-emails/email-action-required-removed/email-action-required-removed.component";
import { StorageReportDrivesComponent } from "./storage-plus/storage/storage-report-drives/storage-report-drives.component";
import { StorageReportSharedDriveComponent } from "./storage-plus/storage/storage-report-shared-drive/storage-report-shared-drive.component";
import { StorageReportOptionComponent } from "./settings/product/storage-report/storage-report-settings/storage-report-options/storage-report-option.component";
import { StorageReportUsersTableComponent } from "./storage-plus/storage/storage-report-users/storage-report-users-table/storage-report-users-table.component";
import { StorageReportFilesTableComponent } from "./storage-plus/storage/storage-report-files/storage-report-files-table/storage-report-files-table.component";
import { StorageReportDrivesTableComponent } from "./storage-plus/storage/storage-report-drives/storage-report-drives-table/storage-report-drives-table.component";
import { EmailDeletedFilesComponent } from "./storage-plus/storage/storage-report-emails/email-deleted-files/email-deleted-files.component";
import { FilterDialogComponent } from "./layout/dialogs/filter-dialog/filter-dialog.component";
import { EditServiceAccountComponent } from "./settings/product/account/dialogs/edit-service-account/edit-service-account.component";
import { TestServiceAccountComponent } from "./settings/product/account/dialogs/test-service-account/test-service-account.component";
import { StorageReportDriveUsersComponent } from "./storage-plus/storage/storage-report-shared-drive/storage-report-drive-users/storage-report-drive-users.component";
import { EmailCustomizationComponent } from "./storage-plus/storage/storage-report-emails/dialogs/email-customization/email-customization.component";
import { EditTenantDatabaseComponent } from "./settings/product/account/dialogs/edit-tenant-database/edit-tenant-database.component";
import { FreeTrialComponent } from "./web/free-trial/free-trial.component";
import { StorageReportStackedHorizontalChartComponent } from "./storage-plus/storage/layout/storage-report-stacked-horizontal-chart/storage-report-stacked-horizontal-chart.component";
import { TransferOwnershipComponent } from "./layout/transfer-ownership/transfer-ownership.component";
import { EnableGoogleCloudStorageComponent } from "./settings/product/storage-report/storage-report-settings/dialogs/enable-google-cloud-storage/enable-google-cloud-storage.component";
import { AssistanceDialogComponent } from "./settings/product/account/dialogs/assistance-dialog/assistance-dialog.component";
import { DialogTransferComponent } from "./layout/dialogs/dialog-transfer/dialog-transfer.component";
import { JobsComponent } from "./storage-plus/jobs/jobs.component";
import { JobsMenuComponent } from "./layout/nav/content-right/jobs-menu/jobs-menu.component";
import { CreateBucketInstructionComponent } from "./settings/product/storage-report/storage-report-settings/dialogs/create-bucket-instruction/create-bucket-instruction.component";
import { StorageReportDuplicateFilesComponent } from "./storage-plus/storage/storage-report-duplicate-files/storage-report-duplicate-files.component";
import { StorageReportDuplicateFilesTableComponent } from "./storage-plus/storage/storage-report-duplicate-files/storage-report-duplicate-files-table/storage-report-duplicate-files-table.component";
import { WebFooterComponent } from "./web/web-footer/web-footer.component";
import { HeaderComponent } from "./web/header/header.component";
import { TransferFileOwnershipComponent } from "./layout/transfer-file-ownership/transfer-file-ownership.component";
import { AddServiceAccountAutomaticallyComponent } from "./settings/product/account/dialogs/add-service-account-automatically/add-service-account-automatically.component";
import { FixDuplicatesDialogComponent } from "./layout/dialogs/fix-duplicates-dialog/fix-duplicates-dialog.component";
import { ManualAddEditSaComponent } from "./settings/product/account/dialogs/edit-service-account/manual-add-edit-sa/manual-add-edit-sa.component";
import { AutomaticAddSaComponent } from "./settings/product/account/dialogs/edit-service-account/automatic-add-sa/automatic-add-sa.component";
import { ApiControlsComponent } from "./settings/product/account/dialogs/api-controls/api-controls.component";
import { ProductUpdatesComponent } from "./product-updates/product-updates.component";
import { OrganizationDialogComponent } from "./layout/organization-dialog/organization-dialog.component";
import { HelpDialogServiceComponent } from "./layout/dialogs/help-dialog-service/help-dialog-service.component";
import { DatePipe } from "@angular/common";
import { MAT_CHIPS_DEFAULT_OPTIONS } from "@angular/material/chips";
import { DialogActionsContainerComponent } from "./layout/dialogs/dialog-actions-container/dialog-actions-container.component";
import { AutoAddErrorDialogComponent } from "./settings/product/account/dialogs/auto-add-error-dialog/auto-add-error-dialog.component";
import { DialogStorageReportMissingScopesComponent } from "./storage-plus/storage/dialogs/dialog-storage-report-missing-scopes/dialog-storage-report-missing-scopes.component";
import { StorageReportMoreInfoComponent } from "./storage-plus/storage/dialogs/storage-report-more-info/storage-report-more-info.component";
import { NotificationDialogComponent } from "./layout/notification-dialog/notification-dialog.component";
import { StorageReportDrivesTableTruncatePipe } from "./storage-plus/storage/storage-report-drives/storage-report-drives-table/storage-report-drives-table-truncate.pipe";
import { StorageOrganizationalUnitsDashboardComponent } from "./storage-plus/storage/storage-organizational-units-dashboard/storage-organizational-units-dashboard.component";
import { StorageReportOuPieChartComponent } from "./storage-plus/storage/layout/storage-report-ou-pie-chart/storage-report-ou-pie-chart.component";
import { StorageReportOuBarChartComponent } from "./storage-plus/storage/layout/storage-report-ou-bar-chart/storage-report-ou-bar-chart.component";
import { StorageReportOrganizationalUnitsTableComponent } from "./storage-plus/storage/storage-organizational-units-dashboard/storage-report-organizational-units-table/storage-report-organizational-units-table.component";
import { StorageReportOrganizationalUnitUsersComponent } from "./storage-plus/storage/storage-organizational-units-dashboard/storage-report-organizational-units-table/storage-report-organizational-unit-users/storage-report-organizational-unit-users.component";
import { GroupSettingsComponent } from "./groups/group/settings/settings.component";
import { ActionDialogComponent } from "./layout/dialogs/action-dialog/action-dialog.component";
import { AddCategoryDialogComponent } from "./settings/product/account/dialogs/category-dialog/category-dialog.component";
import { LinkComponent } from "./layout/leftnav/link/link.component";
import { AuditLogsTableComponent } from "./audit-logs/audit-logs-table.component";
import { TagDialogComponent } from "./settings/product/account/dialogs/tag-dialog/tag-dialog.component";
import { TestMailboxComponent } from "./settings/product/account/dialogs/test-mailbox/test-mailbox.component";
import { ManufacturerDialogComponent } from "./settings/product/account/dialogs/manufacturer-dialog/manufacturer-dialog.component";
import { TypeDialogComponent } from "./settings/product/account/dialogs/type-dialog/type-dialog.component";
import { CustomTicketSettingsDialogComponent } from "./settings/product/account/dialogs/custom-ticket-settings-dialog/custom-ticket-settings-dialog.component";
import { FormTicketDialogComponent } from "./ticket-plus/layout/dialogs/form-ticket-dialog/form-ticket-dialog.component";
import { BuildingRoomDialogComponent } from "./settings/product/account/dialogs/building-room-dialog/building-room-dialog.component";
import { DelegateDialogComponent } from "./settings/product/account/dialogs/delegate-dialog/delegate-dialog.component";
import { StatusDialogComponent } from "./settings/product/account/dialogs/status-dialog/status-dialog.component";
import { ServiceAccountNotificationComponent } from "./layout/service-account-notification/service-account-notification.component";
import { ContentRightComponent } from "./layout/nav/content-right/content-right.component";
import { MenuComponent } from "./layout/nav/content-right/menu/menu.component";
import { ContentLeftComponent } from "./layout/nav/content-left/content-left.component";
import { UserPromptComponent } from "./web/login/user-prompt/user-prompt.component";
import { SignUpComponent } from "./web/free-trial/sign-up/sign-up.component";

@NgModule({
	declarations: [
		AppComponent,
		NavComponent,
		FooterComponent,
		HomeComponent,
		LoginComponent,
		LoaderComponent,
		SpinnerComponent,
		DashboardComponent,
		UsersComponent,
		GroupsComponent,
		LeftnavComponent,
		LogoutComponent,
		GroupDialogComponent,
		UserComponent,
		GroupComponent,
		MembersComponent,
		GroupMembershipsComponent,
		ConfirmationDialogComponent,
		GroupMembershipsDialogComponent,
		MembersDialogComponent,
		DeleteDialogComponent,
		DeleteGroupsDialogComponent,
		DeleteGroupMembershipsDialogComponent,
		UsersNewUserComponent,
		UsersDeleteUsersComponent,
		OuSelectionComponent,
		SelectGroupsComponent,
		EditMemberRolesComponent,
		IdleComponent,
		GroupSettingsComponent,
		SelectGroupsMembersComponent,
		TosPromptComponent,
		StorageComponent,
		StorageReportHistoricalLineChartComponent,
		StorageReportPieChartComponent,
		StorageReportUsersComponent,
		StorageReportFilesComponent,
		StorageReportUserComponent,
		EuaPromptComponent,
		EuaComponent,
		TosComponent,
		ResetPasswordComponent,
		StorageReportEmailsComponent,
		EmailActionRequiredComponent,
		EmailFilesDeletedComponent,
		EmailFilesPendingDeletionComponent,
		EmailHistoryTableComponent,
		EmailReportCompleteComponent,
		ConfirmationDialogTrashComponent,
		EmailActionRequiredRemovedComponent,
		StorageReportDrivesComponent,
		StorageReportSharedDriveComponent,
		StorageReportOptionComponent,
		StorageReportUsersTableComponent,
		StorageReportFilesTableComponent,
		StorageReportDrivesTableComponent,
		EmailDeletedFilesComponent,
		FilterDialogComponent,
		EditServiceAccountComponent,
		TestServiceAccountComponent,
		StorageReportDriveUsersComponent,
		EmailCustomizationComponent,
		EditTenantDatabaseComponent,
		FreeTrialComponent,
		StorageReportStackedHorizontalChartComponent,
		TransferOwnershipComponent,
		EnableGoogleCloudStorageComponent,
		AssistanceDialogComponent,
		DialogTransferComponent,
		JobsComponent,
		JobsMenuComponent,
		CreateBucketInstructionComponent,
		StorageReportDuplicateFilesComponent,
		StorageReportDuplicateFilesTableComponent,
		WebFooterComponent,
		HeaderComponent,
		TransferFileOwnershipComponent,
		AddServiceAccountAutomaticallyComponent,
		FixDuplicatesDialogComponent,
		ManualAddEditSaComponent,
		AutomaticAddSaComponent,
		ApiControlsComponent,
		ProductUpdatesComponent,
		OrganizationDialogComponent,
		HelpDialogServiceComponent,
		DialogActionsContainerComponent,
		AutoAddErrorDialogComponent,
		DialogStorageReportMissingScopesComponent,
		StorageReportMoreInfoComponent,
		NotificationDialogComponent,
		StorageReportDrivesTableTruncatePipe,
		StorageOrganizationalUnitsDashboardComponent,
		StorageReportOuPieChartComponent,
		StorageReportOuBarChartComponent,
		StorageReportOrganizationalUnitsTableComponent,
		StorageReportOrganizationalUnitUsersComponent,
		ActionDialogComponent,
		AddCategoryDialogComponent,
		LinkComponent,
		AuditLogsTableComponent,
		TagDialogComponent,
		TestMailboxComponent,
		ManufacturerDialogComponent,
		TypeDialogComponent,
		CustomTicketSettingsDialogComponent,
		FormTicketDialogComponent,
		BuildingRoomDialogComponent,
		DelegateDialogComponent,
		StatusDialogComponent,
		ServiceAccountNotificationComponent,
		ContentRightComponent,
		MenuComponent,
		ContentLeftComponent,
		UserPromptComponent,
		SignUpComponent,
	],
	entryComponents: [],
	imports: [
		SharedModule,
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		AppRoutingModule,
		NgIdleKeepaliveModule.forRoot(),
	],
	providers: [
		LoginComponent,
		AuthGuard,
		RedirectGuard,
		UsersComponent,
		LeftnavComponent,
		DatePipe,
		LeftNavService,
		AppFiltersComponent,
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				restoreFocus: false,
				hasBackdrop: true,
			},
		},
		{
			provide: MAT_CHIPS_DEFAULT_OPTIONS,
			useValue: {
				separatorKeyCodes: [ENTER, COMMA],
			},
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
