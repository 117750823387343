<app-page-header [options]="options" (optionSelected)="optionSelected($event)"></app-page-header>
<div *ngIf="selected_group">
  <form (ngSubmit)="editGroup()" autocomplete="off">
    <div class="row">
      <div class="col-12 col-md-6 mt-4">
        <mat-form-field class="w-100">
          <mat-label> Name </mat-label>
          <input
            matInput
            [(ngModel)]="this.selected_group.displayName"
            type="text"
            name="name"
            maxlength="75"
            id="name"
            [readonly]="membership !== 'OWNER' && !user.isAdmin"
            autocomplete="off"
            required
          />
        </mat-form-field>
      </div>
      <div class="col-12 col-md-6 mt-4">
        <mat-form-field class="w-100">
          <mat-label> Description </mat-label>
          <input
            matInput
            [(ngModel)]="this.selected_group.description"
            type="text"
            name="description"
            maxlength="300"
            id="description"
            [readonly]="membership !== 'OWNER' && !user.isAdmin"
            autocomplete="off"
            required
          />
        </mat-form-field>
      </div>
      <div class="col-12 mt-3">
        <mat-form-field class="w-100">
          <mat-label>Group Email</mat-label>
          <span matSuffix>@{{ domain }}</span>
          <input
            matInput
            [(ngModel)]="this.email"
            type="text"
            name="email"
            id="email"
            autocomplete="off"
            readonly
            required
          />
        </mat-form-field>
      </div>

      <div *ngIf="membership == 'OWNER' || user.isAdmin" class="col-12 mt-3 d-flex">
        <button mat-stroked-button class="success">Save Group</button>
      </div>

      <div class="col-12 mt-4">
        <mat-accordion class="d-flex w-100" multi>
          <mat-expansion-panel class="w-100" *ngIf="user.isAdmin">
            <mat-expansion-panel-header class="w-100 d-flex align-items-center justify-content-between">
              <mat-panel-title><h3 class="mb-0">Settings</h3> </mat-panel-title>
              <mat-panel-description>
                <div class="d-flex align-items-center ml-auto">
                  <p class="mb-0 mr-3">Advanced group settings</p>
                  <mat-icon>content_paste</mat-icon>
                </div>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <app-group-settings></app-group-settings>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </form>
</div>
<div class="row" *ngIf="selected_group">
  <div class="col-12 mt-4">
    <mat-divider></mat-divider>
    <app-members [enterprise]="enterprise"></app-members>
  </div>
</div>
