<h2 mat-dialog-title>Subscription Expired</h2>

<div mat-dialog-content class="text-center font-weight-bold">
  <h2>Your subscription to Admin+ expired on {{ data.customer.subscription.expires | date : "M/d/yyyy" }}.</h2>
  <div class="d-flex justify-content-center my-4 pt-1">
    <button mat-stroked-button class="ml-2" mat-dialog-close>Not Now</button>
    <button
      mat-stroked-button
      *ngIf="data.renewLink"
      class="d-flex align-items-center ml-1"
      (click)="goToStripe(data.renewLink)"
    >
      <mat-icon class="mr-2">add</mat-icon>Renew Subscription
    </button>
    <h4 *ngIf="data.customer.subscription?.billing != 'CREDIT_CARD'">
      Contact <a href="mailto:sales@trafera.com">sales@trafera.com</a> to discuss renewing your subscription.
    </h4>
  </div>
</div>
