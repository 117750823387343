<app-page-header [options]="options" (optionSelected)="optionSelected($event)"></app-page-header>
<div *ngIf="storageReport.syncError" class="row">
  <div class="col-12 my-2">
    <div class="alert alert-danger text-center pt-3">
      <mat-icon> warning </mat-icon>
      {{ storageReport.syncError }}
    </div>
  </div>
</div>

<div class="row" *ngIf="storageReport.status.id == 4" id="entireScreen">
  <div class="col-12 col-xl-4 mt-2">
    <mat-card class="p-3 h-100">
      <div class="d-flex align-items-center justify-content-center text-center mb-3">
        <mat-icon
          class="icon-display"
          (click)="openMoreInfoDialog()"
          matTooltip="More information on totals"
          aria-label="More information on totals"
          >help
        </mat-icon>
        <p class="mb-0">Domain Storage by Type</p>
      </div>

      <p class="text-center pt-2">
        <span style="font-size: 30px">
          {{ storageReport.totalStorage / divide | number : "1.1-2" }}
          <small class="text-muted">{{ unit }}</small>
        </span>
      </p>

      <div class="graph__icons">
        <span
          class="circleDrive"
          *ngIf="storageReport.totalDrive"
          matTooltip="{{ storageReport.totalDrive / 1024 | number : '1.1-2' }} GB"
          >User Drives
        </span>

        <span
          class="circleGmail"
          *ngIf="storageReport.totalGmail"
          matTooltip="{{ storageReport.totalGmail / 1024 | number : '1.1-2' }} GB"
          >Gmail</span
        >

        <span
          class="circlePhotos"
          *ngIf="storageReport.totalPhotos"
          matTooltip="{{ storageReport.totalPhotos / 1024 | number : '1.1-2' }} GB"
          >Photos</span
        >

        <span
          class="circleTotal"
          *ngIf="storageReport.totalSharedDrive"
          matTooltip="{{ storageReport.totalSharedDrive / 1024 | number : '1.1-2' }} GB"
        >
          Shared Drives
        </span>
      </div>

      <app-storage-report-pie-chart
        *ngIf="storageReport.totalStorage && storageReport.totalStorage"
        [storageByType]="storageByType"
      >
      </app-storage-report-pie-chart>
      <div
        *ngIf="!storageReport.totalStorage || storageReport.totalStorage == 0"
        class="alert alert-danger text-center mt-2"
      >
        No storage data available
      </div>
    </mat-card>
  </div>

  <div class="col-12 col-xl-4 mt-2">
    <mat-card class="p-3 h-100">
      <p class="text-center">Historical Usage</p>
      <p class="text-center pt-2">
        <span style="font-size: 30px">
          {{ storageReport.totalUsers }}
          <small class="text-muted">Users</small>
        </span>
      </p>

      <div class="graph__icons">
        <span class="circleTotal">Total</span>
        <span class="circleDrive">Drive</span>
        <span class="circleGmail">Gmail</span>
        <span class="circlePhotos">Photos</span>
      </div>

      <div class="pt-3 pb-4">
        <app-storage-report-historical-line-chart
          *ngIf="!this.loading && historical && historical.length"
          [data]="historical"
        >
        </app-storage-report-historical-line-chart>
        <div *ngIf="historicalLoading" class="alert alert-info text-center d-flex align-items-center">
          <span class="material-icons mat-icon faa-spin mr-3"> filter_tilt_shift </span>
          Loading Historical Data...
        </div>
        <div *ngIf="!historicalLoading && (!historical || !historical.length)" class="alert alert-danger text-center">
          Unable to retrieve historical data
        </div>
      </div>
    </mat-card>
  </div>

  <div class="col-12 col-xl-4 mt-2">
    <mat-card class="p-3 h-100">
      <p class="text-center">Usage by Organizational Unit</p>
      <p class="text-center pt-2">
        <span *ngIf="storageByOU" style="font-size: 30px">
          {{ storageByOU.length }}
          <small class="text-muted">OUs</small>
        </span>
      </p>
      <div class="pt-3">
        <app-storage-report-stacked-horizontal-chart
          *ngIf="!this.loading && storageByOU && storageByOU.length"
          [data]="storageByOU"
        >
        </app-storage-report-stacked-horizontal-chart>
        <div *ngIf="this.loading" class="alert alert-info text-center d-flex align-items-center">
          <span class="material-icons mat-icon faa-spin mr-3"> filter_tilt_shift </span>
          Loading Organizational Data...
        </div>
        <div *ngIf="!this.loading && (!storageByOU || !storageByOU.length)" class="alert alert-danger text-center mt-2">
          Unable to retrieve organizational data
        </div>
      </div>
    </mat-card>
  </div>

  <ngb-carousel>
    <ng-template ngbSlide>
      <div class="col-12 col-xl-4 mt-3" id="domainChart">
        <mat-card class="p-3 h-100" (click)="viewUserFiles()">
          <p class="text-center">Domain Storage by Type</p>
          <p class="text-center pt-2">
            <span style="font-size: 30px">
              {{ storageReport.totalStorage / divide | number : "1.1-2" }}
              <small class="text-muted">{{ unit }}</small>
            </span>
          </p>
          <div class="graph__icons">
            <span class="circleDrive" matTooltip="{{ storageReport.totalDrive / 1024 | number : '1.1-2' }} GB">Drive</span>

            <span class="circleGmail" matTooltip="{{ storageReport.totalGmail / 1024 | number : '1.1-2' }} GB">Gmail</span>

            <span class="circlePhotos" matTooltip="{{ storageReport.totalPhotos / 1024 | number : '1.1-2' }} GB"
              >Photos</span
            >
          </div>

          <app-storage-report-pie-chart
            *ngIf="storageReport.totalStorage && storageReport.totalStorage"
            [storageByType]="storageByType"
          >
          </app-storage-report-pie-chart>
          <div
            *ngIf="!storageReport.totalStorage || storageReport.totalStorage == 0"
            class="alert alert-danger text-center mt-2"
          >
            No storage data available
          </div>
        </mat-card>
      </div>
    </ng-template>

    <ng-template ngbSlide>
      <div class="col-12 col-xl-4 mt-3" id="historicalChart">
        <mat-card class="p-3 h-100" (click)="viewUsers()">
          <p class="text-center">Historical Usage</p>
          <p class="text-center pt-2">
            <span style="font-size: 30px">
              {{ storageReport.totalUsers }}
              <small class="text-muted">Users</small>
            </span>
          </p>
          <div class="graph__icons">
            <span class="circleTotal">Total</span>
            <span class="mr-2 circleDrive">Drive</span>
            <span class="circleGmail">Gmail</span>
            <span class="circlePhotos">Photos</span>
          </div>

          <div class="pt-3 pb-4">
            <app-storage-report-historical-line-chart
              *ngIf="!this.loading && historical && historical.length"
              [data]="historical"
            >
            </app-storage-report-historical-line-chart>
            <div *ngIf="this.loading" class="alert alert-info text-center d-flex align-items-center">
              <span class="material-icons mat-icon faa-spin mr-3"> filter_tilt_shift </span>
              Loading Historical Data...
            </div>
            <div *ngIf="!this.loading && (!historical || !historical.length)" class="alert alert-danger text-center mt-2">
              Unable to retrieve historical data
            </div>
          </div>
        </mat-card>
      </div>
    </ng-template>

    <ng-template ngbSlide>
      <div class="col-12 col-xl-4 mt-3" id="ouChart">
        <mat-card class="p-3 h-100" (click)="viewUsers()">
          <p class="text-center">Usage by Organizational Unit</p>
          <p class="text-center pt-2">
            <span *ngIf="storageByOU" style="font-size: 30px">
              {{ storageByOU.length }}
              <small class="text-muted">OUs</small>
            </span>
          </p>
          <div class="pt-3">
            <app-storage-report-stacked-horizontal-chart
              *ngIf="!this.loading && storageByOU && storageByOU.length"
              [data]="storageByOU"
            >
            </app-storage-report-stacked-horizontal-chart>
            <div *ngIf="this.loading" class="alert alert-info text-center">
              <span class="material-icons mat-icon faa-spin mr-3"> filter_tilt_shift </span>
              Loading Organizational Data...
            </div>
            <div *ngIf="!this.loading && (!storageByOU || !storageByOU.length)" class="alert alert-danger text-center mt-2">
              Unable to retrieve organizational data
            </div>
          </div>
        </mat-card>
      </div>
    </ng-template>
  </ngb-carousel>

  <div class="col-12 my-2">
    <mat-card class="p-3">
      <div class="d-flex align-items-center mb-3 px-3">
        <h2 class="mb-0 mr-3">Users</h2>
        <span class="h6 mb-0 mr-2">Users Cached:</span>
        <strong class="h6 mb-0">{{ storageReport.usageSynced | date : "short" }}</strong>

        <button (click)="viewUsers()" mat-stroked-button class="success ml-auto">View All Users</button>
      </div>
      <app-storage-report-users-table [storageReport]="storageReport"></app-storage-report-users-table>
    </mat-card>
  </div>

  <div class="col-12 my-2">
    <mat-card class="px-3 pt-3">
      <div class="d-flex align-items-center mb-3 px-3">
        <h2 class="mb-0 mr-3">Files</h2>
        <span class="h6 mb-0 mr-2">Files Cached:</span>
        <strong class="mb-0 h6">{{ storageReport.filesSynced | date : "short" }}</strong>
        <button type="button" (click)="viewUserFiles()" mat-stroked-button class="success ml-auto">View All Files</button>
      </div>
      <app-storage-report-files-table [storageReport]="storageReport"></app-storage-report-files-table>
    </mat-card>
  </div>

  <div class="col-12 my-2">
    <mat-card *ngIf="loginService.user.isAdmin" class="px-3 pt-3">
      <div class="d-flex flex-wrap align-items-center mb-3 px-3">
        <h2 class="mb-0 mr-3">Shared Drives</h2>
        <span class="h6 mb-0 mr-2">Shared Drives Cached:</span>
        <strong class="h6 mb-0">{{ storageReport.drivesSynced | date : "short" }}</strong>

        <button type="button" (click)="viewDrives()" mat-stroked-button class="success ml-auto">View All Drives</button>
      </div>
      <app-storage-report-drives-table [storageReport]="storageReport"></app-storage-report-drives-table>
    </mat-card>
  </div>
</div>

<div class="row" *ngIf="!this.loading && this.storageReport.id == '0'">
  <div class="col-12 my-2">
    <div class="alert alert-warning text-center">
      <p class="mt-2">Storage reports have not been configured for your account.</p>
      <p>
        <strong>
          <span class="material-icons mat-icon mr-2"> contact_support </span>
          Why am I getting this message?
        </strong>
      </p>
      <p *ngIf="loginService.user.isAdmin && this.storageReport.id == '0'">
        A Storage report must be created (can be added in the config section).
        <span (click)="viewConfig()" class="cursor-pointer ml-2 mat-icon material-icons">settings</span>
      </p>
      <p *ngIf="loginService.user.isAdmin">
        If you've setup a Storage report, service account, and passed the connection test, there may be an error. Please
        contact
        <a href="mailto:google.support@trafera.com">google.support@trafera.com</a>
      </p>
      <p *ngIf="!loginService.user.isAdmin">Please check with your administrator to configure Storage reports.</p>
    </div>
  </div>
</div>
<div class="row" *ngIf="storageReport.status.id == 3">
  <div class="col-12 my-2">
    <div class="alert alert-warning text-center">
      <p class="mt-2">This report requires user input and is currently pending<br /></p>
      <p>
        <span class="material-icons mat-icon mr-2"> contact_support </span>
        If you have any questions please contact google.support@trafera.com
      </p>
    </div>
  </div>
</div>
<div class="row" *ngIf="storageReport.status.id == 2">
  <div class="col-12 my-2">
    <div class="alert alert-info text-center">
      <p class="mt-2">
        <strong> This report is running, please check back soon. </strong>
      </p>
      <p>
        <i>If you're the administrator who initiated the report, you will be emailed once the report is ready to view.</i>
      </p>
      <p>
        <small class="text-muted"> Started: {{ storageReport.lastRun | date : "short" }} </small>
      </p>
      <p>
        <span class="material-icons mat-icon mr-2"> contact_support </span>
        If you have any questions please contact google.support@trafera.com
      </p>
      <p class="pb-2">
        Users synced so far: {{ storageReport.totalUsers }}
        <span (click)="refresh()" class="cursor-pointer ml-2 mat-icon material-icons">refresh</span>
      </p>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </div>
</div>
<div class="row" *ngIf="storageReport.status.id == 1">
  <div class="col-12 my-2">
    <div class="alert alert-warning text-center">
      <p class="mt-2">This report is currently in a state of "Created" and still needs to be run</p>
      <p>
        <a (click)="buildReport()" mat-stroked-button class="primary">
          <span class="material-icons mat-icon mr-2"> trending_up </span>
          Run Now
        </a>
      </p>
    </div>
  </div>
</div>
