<h2 mat-dialog-title>New User</h2>
<form (ngSubmit)="processForm()" autocomplete="off">
	<div mat-dialog-content>
		<div class="row">
			<div class="col-12 mt-2">
				<mat-form-field class="w-100">
					<mat-label> First Name </mat-label>
					<input
						matInput
						[(ngModel)]="firstname"
						type="text"
						name="firstname"
						id="firstname"
						placeholder="First name *"
						autocomplete="off"
						required
					/>
				</mat-form-field>
			</div>
			<div class="col-12 mt-2">
				<mat-form-field class="w-100">
					<mat-label> Last Name </mat-label>
					<input
						matInput
						[(ngModel)]="lastname"
						type="text"
						name="lastname"
						id="lastname"
						placeholder="Last name *"
						autocomplete="off"
						required
					/>
				</mat-form-field>
			</div>
			<div class="col-12 mt-2">
				<app-organizational-unit id="org_unit" name="org_unit" [(ngModel)]="org_unit" required></app-organizational-unit>
			</div>
			<div class="col-12 mt-2">
				<mat-form-field class="w-100">
					<mat-label>Primary Email</mat-label>
					<input
						matInput
						[(ngModel)]="primaryemail"
						type="text"
						name="primaryemail"
						id="primaryemail"
						placeholder="Primary email *"
						autocomplete="off"
						required
					/>
					<span matSuffix>@{{ domain }}</span>
				</mat-form-field>
			</div>
			<div class="col-12 mt-2">
				<mat-form-field class="w-100">
					<mat-label>Password</mat-label>
					<input
						matInput
						[(ngModel)]="password"
						type="password"
						name="password"
						id="password"
						placeholder="Password *"
						autocomplete="off"
					/>
				</mat-form-field>
			</div>
		</div>
		<app-dialog-actions-container></app-dialog-actions-container>
	</div>
</form>
