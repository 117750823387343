<app-page-header [options]="options" (optionSelected)="optionSelected($event)"></app-page-header>

<form *ngIf="selected_user" (ngSubmit)="editUser()" autocomplete="off">
  <div class="row">
    <div class="col-12 col-md-6 mt-2">
      <mat-form-field class="w-100">
        <mat-label> First name </mat-label>
        <input
          matInput
          [(ngModel)]="selected_user.name.givenName"
          type="text"
          name="firstname"
          id="firstname"
          autocomplete="off"
          required
        />
      </mat-form-field>
    </div>
    <div class="col-12 col-md-6 mt-2">
      <mat-form-field class="w-100">
        <mat-label> Last name </mat-label>
        <input
          matInput
          [(ngModel)]="selected_user.name.familyName"
          type="text"
          name="lastname"
          id="lastname"
          autocomplete="off"
          required
        />
      </mat-form-field>
    </div>
    <div class="col-12 col-md-6 mt-2">
      <app-organizational-unit
        [(ngModel)]="selected_user.orgUnitPath"
        name="org_unit"
        id="org_unit"
        required
      ></app-organizational-unit>
    </div>
    <div class="col-12 col-md-6 mt-2">
      <mat-form-field class="w-100">
        <mat-label> Primary Email </mat-label>
        <input
          matInput
          [(ngModel)]="primaryemail"
          type="text"
          name="primaryemail"
          id="primaryemail"
          placeholder="{{ selected_user.primaryEmail }}"
          autocomplete="off"
          required
        />
        <span matSuffix>@{{ domain }}</span>
      </mat-form-field>
    </div>
    <div class="col-12 mt-3">
      <button class="success" type="submit" mat-stroked-button>Save User</button>
    </div>
  </div>
</form>
<div class="row">
  <div class="col-12">
    <mat-divider class="my-4 mt-5"></mat-divider>
    <app-group-memberships *ngIf="groupPermissionSet && groupPermission" [groups]="memberships"></app-group-memberships>
    <div *ngIf="groupPermissionSet && !groupPermission" class="alert alert-danger text-center">
      You do not have permission to view this user's groups.
    </div>
  </div>
</div>
