<app-page-header [options]="options" (optionSelected)="optionSelected($event)"></app-page-header>
<div class="row">
  <div class="col-12 mt-2">
    <div class="sa__wrapper">
      <div class="d-flex flex-column justify-content-center align-items-center bg-white">
        <div class="page-loading m-5" *ngIf="loading">
          <mat-spinner color="primary" class="page-loading__spinner" diameter="60"></mat-spinner>
        </div>
        <div *ngIf="!loading" class="w-100">
          <ul class="list-unstyled sa__list w-100">
            <li class="sa__field">
              <div class="d-flex align-items-center">
                <mat-icon matTooltip="Name" class="sa__field-icon"> subtitles </mat-icon>
                <span class="sa__label sa__label--width text-uppercase ml-2">Name</span>
                <h4 class="ls-4 mb-0">{{ customer.name }}</h4>
              </div>
            </li>
            <li class="sa__field">
              <div class="d-flex align-items-center">
                <mat-icon matTooltip="Domain" class="sa__field-icon"> public </mat-icon>
                <span class="sa__label sa__label--width text-uppercase ml-2">Domain</span>
                <h4 class="ls-4 mb-0">{{ account?.domain?.name }}</h4>
              </div>
            </li>
            <li class="sa__field">
              <div class="d-flex align-items-center">
                <mat-icon matTooltip="Time Zone" class="sa__field-icon"> language </mat-icon>
                <span class="sa__label sa__label--width text-uppercase ml-2">Time Zone</span>
                <h4 class="ls-4 mb-0">{{ customer.timezone }}</h4>
              </div>
            </li>
            <li class="sa__field">
              <div class="d-flex align-items-center">
                <mat-icon matTooltip="Client ID" class="sa__field-icon"> person </mat-icon>
                <span class="sa__label sa__label--width text-uppercase ml-2">Customer ID</span>
                <h4 class="ls-4 mb-0">{{ account?.customer?.customerId }}</h4>
              </div>
            </li>
            <li class="sa__field">
              <div class="d-flex align-items-center">
                <mat-icon matTooltip="Billing" class="sa__field-icon"> monetization_on </mat-icon>
                <span class="sa__label sa__label--width text-uppercase ml-2">Billing</span>
                <h4 *ngIf="customer.subscription?.billing !== 'CREDIT_CARD'" class="ls-4 mb-0">
                  Contact <a href="mailto:sales@trafera.com">sales@trafera.com</a> for billing questions.
                </h4>
                <button
                  mat-stroked-button
                  *ngIf="customer.subscription?.billing == 'CREDIT_CARD'"
                  class="d-flex align-items-center"
                  [disabled]="!stripeLink"
                  (click)="goToStripe(stripeLink)"
                >
                  <mat-icon class="mr-2">manage_search</mat-icon>{{ renewLink ? "Customer Portal" : "Manage Subscription" }}
                </button>
                <button
                  mat-stroked-button
                  *ngIf="renewLink"
                  class="d-flex align-items-center ml-1"
                  (click)="goToStripe(renewLink)"
                >
                  <mat-icon class="mr-2">add</mat-icon>Renew Subscription
                </button>
              </div>
            </li>
            <li class="sa__field">
              <div
                class="d-flex align-items-center"
                [ngClass]="{
                  'emp text-danger': account?.customer?.subscription?.expired
                }"
              >
                <mat-icon matTooltip="Subscription Details" class="sa__field-icon"> subscriptions </mat-icon>
                <span class="sa__label sa__label--width text-uppercase ml-2">
                  Subscription <span class="px-1">|</span> <small> Expiration</small></span
                >
                <div class="d-flex align-items-center">
                  <mat-icon *ngIf="account?.customer?.subscription.expired" class="mr-3">warning</mat-icon>
                  <div *ngIf="account?.customer?.subscription.expires">
                    <h4 class="mb-0 ls-1">
                      {{ account?.customer?.subscription.name }}
                    </h4>
                    <h4 class="mb-0 mt-n1 ls-1">
                      {{ account?.customer?.subscription.expires | date : "short" }}
                    </h4>
                  </div>
                  <div
                    *ngIf="account?.customer?.subscription?.expired && !account?.customer?.subscription?.expires"
                    class="d-flex align-items-center"
                  >
                    No subscription found, click manage subscription above to get started.
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
